import { Box, Divider, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { ConfigurationProps } from '../../../pages/Configuration/types/Configuration.types';
import { ResurfaceBanner } from './ResurfaceBanner';

export const CmpSettingsTab = (props: ConfigurationProps) => {
    const { configuration, saveConfiguration } = props;

    const { t } = useTranslation('translation', {
        keyPrefix: 'configurationPage.cmpSettings'
    });

    return (
        <Box display="flex" flexDirection="column" gap={4}>
            <Text fontSize="28px" fontWeight="800">
                {t('tabTitle')}
            </Text>

            <Divider />

            <ResurfaceBanner
                configuration={configuration}
                saveConfiguration={saveConfiguration}
            />
        </Box>
    );
};
