import { Divider } from '@chakra-ui/react';
import { CustomEmbedModalFormState } from '@components/ComponentItem/CustomEmbedModal/types';
import {
    Box,
    Grid,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    MdDeleteOutline,
    MdSearch,
    MultiSelect,
    MultiSelectButton,
    MultiSelectList,
    MultiSelectOption,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { ChangeEvent } from 'react';

type Props = {
    selectedUrls: CustomEmbedModalFormState['selectedUrls'];
    updateForm: (data: { id: string; value: string[] }) => void;
    t: (key: string) => string;
    filteredUrls: string[];
    setSearch: (value: string) => void;
    search: string;
    sendEvent: (
        title: string,
        eventProperties?: Record<string, any> | undefined
    ) => void;
};

export const CustomEmbedMultiSelect = ({
    selectedUrls,
    filteredUrls,
    search,
    setSearch,
    updateForm,
    t,
    sendEvent
}: Props) => {
    const handleOnMappingChange = (event: ChangeEvent<any>) => {
        sendEvent('Custom Embed Mapping Changed', {
            value: event.target.value
        });

        updateForm({
            id: 'selectedUrls',
            value: event.target.value
        });
    };

    const handleOnSearchChange = (event: ChangeEvent<any>) => {
        sendEvent('Custom Embed Mapping Search Changed', {
            value: event.target.value
        });

        setSearch(event.target.value);
    };

    const handleOnRemoveUrl = (url: string) => {
        sendEvent('Custom Embed Mapping URL Removed', {
            url
        });

        updateForm({
            id: 'selectedUrls',
            value: selectedUrls.filter(
                (alreadySelectedUrl) => alreadySelectedUrl !== url
            )
        });
    };

    return (
        <Box width="100%">
            <Text fontWeight={600}>
                {t('componentsPage.customEmbeds.mappingUnknownCalls')}
            </Text>
            <MultiSelect
                width="100%"
                onChange={handleOnMappingChange}
                value={selectedUrls}
                onClose={() => setTimeout(() => setSearch(''), 100)}
            >
                <MultiSelectButton>
                    <Text display="flex" gap="8px" alignItems="center">
                        {selectedUrls.length} {t('common.selected')}
                    </Text>
                </MultiSelectButton>
                <MultiSelectList width="auto">
                    <InputGroup w="auto" my="4px">
                        <Input
                            placeholder={t(
                                'componentsPage.customEmbeds.mappingUnknownCallsSearchPlaceholder'
                            )}
                            onChange={handleOnSearchChange}
                            value={search}
                        />
                        <InputRightElement>
                            <Icon as={MdSearch} />
                        </InputRightElement>
                    </InputGroup>
                    <Divider color="#D6D6D6" />
                    {filteredUrls.map((url) => (
                        <MultiSelectOption
                            key={url}
                            value={url}
                            type="checkmark"
                        >
                            {url}
                        </MultiSelectOption>
                    ))}
                </MultiSelectList>
            </MultiSelect>
            <Box mt="8px">
                {selectedUrls.map((url, index) => (
                    <Grid gridTemplateColumns="1fr min-content" key={url}>
                        <Text
                            fontWeight={600}
                            color="#1032CF"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                        >
                            {index + 1}. {url}
                        </Text>
                        <Box
                            px="16px"
                            justifySelf="end"
                            _hover={{
                                cursor: 'pointer'
                            }}
                            onClick={() => handleOnRemoveUrl(url)}
                        >
                            <MdDeleteOutline size={24} fill="#666666" />
                        </Box>
                    </Grid>
                ))}
            </Box>
        </Box>
    );
};
