import { useCallback, useState } from 'react';

import { UpdateFormInput } from '../types/general';
import { useFormState } from './useFormState';

export const useForm = <T>(defaultFormState: T) => {
    const [formState, setFormState] = useState<T>(defaultFormState);
    const { updateForm } = useFormState();

    const reset = useCallback(() => {
        setFormState(defaultFormState);
    }, [defaultFormState]);

    return {
        update: (payload: UpdateFormInput) => updateForm(setFormState, payload),
        formState,
        reset,
        setFormState
    };
};
