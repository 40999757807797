import { proxy, useSnapshot } from 'valtio';

type State = {
    isAdmin: boolean;
};

const defaultState: State = {
    isAdmin: false,
};

const state = proxy(defaultState);

export const adminStoreActions = {
    setIsAdmin: (isAdmin: boolean) => {
        state.isAdmin = isAdmin;
    }
};

export const useAdminStore = () => useSnapshot<State>(state);
