import { CustomEmbedDescription } from '@components/ComponentItem/CustomEmbedModal/CustomEmbedDescription';
import { CustomEmbedLiveSwitch } from '@components/ComponentItem/CustomEmbedModal/CustomEmbedLiveSwitch';
import { CustomEmbedMultiSelect } from '@components/ComponentItem/CustomEmbedModal/CustomEmbedMultiSelect';
import { CustomEmbedTitle } from '@components/ComponentItem/CustomEmbedModal/CustomEmbedTitle';
import { useCustomEmbedModal } from '@components/ComponentItem/CustomEmbedModal/useCustomEmbedModal';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack
} from '@usercentrics/cookiebot-ui-react';

import { Component } from '../../../types/general';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    component: Component | null;
};

export const CustomEmbedModal = (props: Props) => {
    const { isOpen, onClose, component } = props;
    const {
        t,
        handleSave,
        filteredUrls,
        isLoading,
        setSearch,
        formState,
        updateForm,
        search,
        sendEvent
    } = useCustomEmbedModal({ component, onClose });

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {t('componentsPage.customEmbeds.editComponent')}
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <Stack spacing="24px">
                        <CustomEmbedTitle
                            title={formState.title}
                            updateForm={updateForm}
                            t={t}
                            sendEvent={sendEvent}
                        />
                        <CustomEmbedDescription
                            description={formState.description}
                            updateForm={updateForm}
                            t={t}
                            sendEvent={sendEvent}
                        />
                        <CustomEmbedMultiSelect
                            selectedUrls={formState.selectedUrls}
                            updateForm={updateForm}
                            filteredUrls={filteredUrls}
                            setSearch={setSearch}
                            search={search}
                            t={t}
                            sendEvent={sendEvent}
                        />
                        <CustomEmbedLiveSwitch
                            isActiveInBanner={formState.isActiveInBanner}
                            updateForm={updateForm}
                            t={t}
                            sendEvent={sendEvent}
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="transparent"
                        onClick={onClose}
                        isDisabled={isLoading}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        colorScheme="blue"
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        onClick={handleSave}
                    >
                        {t('common.saveChanges')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
