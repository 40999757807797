import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getBannerComponents, getCustomComponents } from '../api/api';
import { useAuthStore } from '../stores';
import { Component, ComponentState } from '../types/general';
import { groupComponentsBy } from '../util/groupComponentsBy';

export const useComponents = () => {
    const navigate = useNavigate();

    const { appInstanceId, domain } = useAuthStore();

    const { data, isLoading } = useQuery({
        queryKey: ['bannerComponents', { appInstanceId, domainUrl: domain }],
        queryFn: () => getBannerComponents({ appInstanceId, domainUrl: domain })
    });

    const { data: customComponentsData } = useQuery({
        queryKey: ['getCustomComponents', appInstanceId],
        queryFn: () => getCustomComponents(appInstanceId)
    });

    const components = useMemo<Component[]>(() => {
        if (data) {
            const dataComponents =
                data.components?.map((component: any) => ({
                    ...component,
                    blockerCategory: component.category
                })) ?? [];
            const { otherServices } = data;
            let otherServicesComponents: Component[] = [];

            if (otherServices?.length) {
                otherServicesComponents = otherServices.map((url: string) => ({
                    name: url,
                    hasConflict: true,
                    type: 'otherServices'
                }));
            }

            if (dataComponents) {
                return [...dataComponents, ...otherServicesComponents];
            }

            return [];
        }

        return [];
    }, [data]);

    useEffect(() => {
        if (data && !components.length) {
            navigate('/banner/appearance');
        }
    }, [components.length, data, navigate]);

    const componentsWithCustomEmbeds = useMemo(() => {
        const customEmbeds =
            customComponentsData?.map((component) => ({
                id: component.id,
                name: component.name,
                title: component.bannerTitle,
                description: component.bannerDescription,
                mappedUrls: component.mappedUrls,
                recommendedCategory: component.category,
                blockerCategory: component.category,
                type: 'customEmbeds',
                recommendedAction: {
                    value: '',
                    error: ''
                },
                blockedPriorConsent: false,
                hasConflict: false,
                badgeStatus: 'grey',
                state: ComponentState.NONE,
                templateId: component.templateId,
                recommendationIgnored: false,
                settingId: component.settingId,
                isActiveInBanner: component.isActiveInBanner
            })) ?? [];

        return [...components, ...customEmbeds];
    }, [components, customComponentsData]);

    const groupedComponents = useMemo(() => {
        if (componentsWithCustomEmbeds) {
            return groupComponentsBy(componentsWithCustomEmbeds);
        }

        return [];
    }, [componentsWithCustomEmbeds]);

    return {
        isLoading,
        groupedComponents
    };
};
