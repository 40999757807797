import {
    Grid,
    Input,
    Select,
    SelectButton,
    SelectList,
    SelectOption
} from '@usercentrics/cookiebot-ui-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontsFromState } from '../../../pages/Appearance/types/consentBanner.type';
import { FormUpdate } from '../../../types/general';
import { FONT_FAMILIES, FONT_SIZES } from '../../../util/constants';

type Props = FormUpdate & {
    fontsFormState: FontsFromState;
};

export const ConsentBannerFonts = ({ updateForm, fontsFormState }: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.consentBanner.bannerFonts'
    });

    const [customValueInput, setCustomValueInput] = useState<string>(
        fontsFormState.family
    );
    const [selectedFontFamily, setSelectedFontFamily] = useState<string>(
        fontsFormState.family
    );

    const selectedValue =
        selectedFontFamily === 'custom' ? 'custom' : selectedFontFamily;

    const isCustom = selectedValue === 'custom';

    useEffect(() => {
        const isFontCustom = !FONT_FAMILIES.some(
            (option) => option.value === fontsFormState.family
        );

        if (isFontCustom) {
            setSelectedFontFamily('custom');
            setCustomValueInput(fontsFormState.family);
        } else {
            setSelectedFontFamily(fontsFormState.family);
            setCustomValueInput('');
        }
    }, [fontsFormState.family]);

    useEffect(() => {
        if (isCustom) {
            updateForm({
                formSection: 'fonts',
                id: 'family',
                value: customValueInput
            });
        } else {
            updateForm({
                formSection: 'fonts',
                id: 'family',
                value: selectedFontFamily
            });
        }
    }, [updateForm, customValueInput, isCustom, selectedFontFamily]);

    const onChangeFontFamily = (event: any) => {
        const { value } = event.target;
        setSelectedFontFamily(value);

        if (value === 'custom') {
            updateForm({
                formSection: 'fonts',
                id: 'family',
                value: customValueInput
            });
        } else {
            updateForm({
                formSection: 'fonts',
                id: 'family',
                value
            });
        }
    };

    const handleCustomInputChange = (event: any) => {
        setCustomValueInput(event.target.value);
    };

    return (
        <Grid templateRows="1fr" templateColumns="1fr 1fr" gap="20px">
            <Select
                width="500px"
                size="sm"
                value={selectedValue}
                onChange={onChangeFontFamily}
            >
                <SelectButton width="500px">
                    {t('fontFamilySelector')}
                </SelectButton>
                <SelectList width="500px">
                    {FONT_FAMILIES.map((option) => (
                        <SelectOption value={option.value} key={option.value}>
                            {option.label}
                        </SelectOption>
                    ))}
                    <SelectOption value="custom" key="custom">
                        {t('customFontFamilySelector')}
                    </SelectOption>
                </SelectList>
            </Select>

            <Select
                width="500px"
                size="sm"
                value={fontsFormState.size}
                onChange={(event) => {
                    updateForm({
                        formSection: 'fonts',
                        id: 'size',
                        value: event.target.value
                    });
                }}
            >
                <SelectButton width="500px">
                    {t('fontSizeSelector')}
                </SelectButton>
                <SelectList width="500px">
                    {FONT_SIZES.map((option) => (
                        <SelectOption value={option.value} key={option.value}>
                            {option.label}
                        </SelectOption>
                    ))}
                </SelectList>
            </Select>

            {isCustom && (
                <Input
                    type="text"
                    value={customValueInput}
                    onChange={handleCustomInputChange}
                    placeholder={t('custonFontFamilyInputPlaceHolder')}
                />
            )}
        </Grid>
    );
};
