import { CustomEmbedModalFormState } from '@components/ComponentItem/CustomEmbedModal/types';
import { Box, Input, Text } from '@usercentrics/cookiebot-ui-react';

type Props = {
    title: CustomEmbedModalFormState['title'];
    updateForm: (data: { id: string; value: string }) => void;
    t: (key: string) => string;
    sendEvent: (
        title: string,
        eventProperties?: Record<string, any> | undefined
    ) => void;
};

export const CustomEmbedTitle = ({
    title,
    updateForm,
    t,
    sendEvent
}: Props) => (
    <Box>
        <Text fontWeight={600}>
            {t('componentsPage.customEmbeds.titleInBanner')}
        </Text>
        <Input
            value={title}
            onChange={(event) =>
                updateForm({
                    id: 'title',
                    value: event.target.value
                })
            }
            onBlur={(event) =>
                sendEvent('Custom Embed Title Changed', {
                    value: event.target.value
                })
            }
            placeholder={t(
                'componentsPage.customEmbeds.titleInBannerPlaceholder'
            )}
        />
    </Box>
);
