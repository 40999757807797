import { Divider } from '@chakra-ui/react';
import { Accordion, Box, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { PrivacyTriggerFormState } from '../../pages/Appearance/types/privacyTrigger.type';
import { UpdateFormInput } from '../../types/general';
import { ColorsAccordionWrapper } from '../ColorsAccordionWrapper/ColorsAccordionWrapper';
import { PrivacyTriggerIcon } from './PrivacyTriggerIcon/PrivacyTriggerIcon';
import { PrivacyTriggerPlacement } from './PrivacyTriggerPlacement/PrivacyTriggerPlacement';
import { PrivacyTriggerIconColors } from './PrviacyTriggerIconColors/PrivacyTriggerIconColors';

type Props = {
    updateForm: (payload: UpdateFormInput) => void;
    formState: PrivacyTriggerFormState;
};

export const PrivacyTriggerTab = ({ updateForm, formState }: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.privacyTrigger'
    });

    return (
        <Box>
            <Stack spacing="40px">
                <Box
                    backgroundColor="#F2F2F2"
                    borderRadius="16px"
                    px="40px"
                    py="40px"
                >
                    <Stack spacing="2">
                        <Text fontSize="28px" fontWeight="700">
                            {t('title')}
                        </Text>
                        <Text fontSize="15px" color="#141414">
                            {t('subTitle')}
                        </Text>
                    </Stack>
                </Box>
                <Stack spacing="32px">
                    <PrivacyTriggerPlacement
                        updateForm={updateForm}
                        placement={formState.placement}
                    />
                    <Divider />
                    <PrivacyTriggerIcon
                        updateForm={updateForm}
                        icon={formState.icon}
                        background={formState.iconColors.background}
                        iconColor={formState.iconColors.icon}
                    />

                    <Stack spacing="16px">
                        <Accordion reduceMotion defaultIndex={0}>
                            <ColorsAccordionWrapper title={t('colors.title')}>
                                <PrivacyTriggerIconColors
                                    background={formState.iconColors.background}
                                    icon={formState.iconColors.icon}
                                    updateForm={updateForm}
                                    formSection="iconColors"
                                />
                            </ColorsAccordionWrapper>
                        </Accordion>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
};
