import { Box, Checkbox, Text } from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigurationProps } from '../../../pages/Configuration/types/Configuration.types';

export const HideDoNotSellMyInformation = (props: ConfigurationProps) => {
    const { configuration, saveConfiguration } = props;
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation('translation', {
        keyPrefix: 'configurationPage.legal'
    });

    const handleCheck = (value: boolean) => {
        setIsLoading(true);

        saveConfiguration({
            ...configuration,
            ccpa: {
                ...configuration.ccpa,
                doNotSellPersonalInformationToggleVisible: value
            }
        }).finally(() => setIsLoading(false));
    };

    return (
        <Box display="flex" p={4} gap={2}>
            <Checkbox
                size="lg"
                mt={1}
                isChecked={
                    configuration.ccpa
                        ?.doNotSellPersonalInformationToggleVisible
                }
                onChange={(e) => handleCheck(e.target.checked)}
                isDisabled={isLoading}
            />
            <Box display="flex" flexDir="column" gap={2}>
                <Text fontSize="18px" fontWeight="600">
                    {t('hideDoNotSellMyInformationTitle')}
                </Text>
                <Text>{t('hideDoNotSellMyInformationDescription')}</Text>
            </Box>
        </Box>
    );
};
