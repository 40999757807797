import {
    Box,
    ColorPicker,
    Grid,
    Input,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonColors } from '../../../pages/Appearance/types/consentBanner.type';
import { FormUpdate } from '../../../types/general';

type Props = FormUpdate & { colors: ButtonColors };

export const ConsentBannerButtonsColors = ({ updateForm, colors }: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.consentBanner.buttonColors'
    });
    const colorsKeys = useMemo(() => {
        if (colors) {
            return Object.keys(colors);
        }

        return [];
    }, [colors]);

    return (
        <Box>
            <Grid
                templateRows="1fr 1fr 1fr"
                templateColumns="1fr 1fr"
                gap="20px"
            >
                {colorsKeys.map((colorKey) => (
                    <Box key={colorKey}>
                        <Text fontSize={14} fontWeight={600}>
                            {t(colorKey)}
                        </Text>
                        <Box display="flex" gap="8px">
                            <ColorPicker
                                value={colors[colorKey] as string}
                                onChange={(event: any) =>
                                    updateForm({
                                        formSection: 'buttonColors',
                                        id: colorKey,
                                        value: event.target.value
                                    })
                                }
                            />
                            <Input
                                value={colors[colorKey] as string}
                                onChange={(event) => {
                                    updateForm({
                                        formSection: 'buttonColors',
                                        id: colorKey,
                                        value: event.target.value
                                    });
                                }}
                            />
                        </Box>
                    </Box>
                ))}
            </Grid>
        </Box>
    );
};
