export const themes = {
    default: {
        consentBanner: {
            layoutType: 'banner',
            theme: 'default',
            generalColors: {
                backgroundColor: '#FFFFFF',
                linkColor: '#1032CF',
                tabColor: '#1032CF',
                textColor: '#141414',
                accentColor: '#D6D6D6'
            },
            buttonColors: {
                acceptButtonBackground: '#1032CF',
                acceptButtonText: '#FFFFFF',
                denyButtonBackground: '#1032CF',
                denyButtonText: '#FFFFFF',
                saveButtonBackground: '#1032CF',
                saveButtonText: '#FFFFFF',
                moreBtnBackground: '#FFFFFF',
                moreBtnText: '#1032CF'
            },
            toggleColors: {
                activeToggleBackground: '#1032CF',
                activeToggleIcon: '#FFFFFF',
                disabledToggleBackground: '#D6D6D6',
                disabledToggleIcon: '#FFFFFF',
                inactiveToggleBackground: '#141414',
                inactiveToggleIcon: '#FFFFFF'
            },
            advancedSettings: {
                useBackgroundShadow: true,
                backgroundOverlay: true,
                overlayOpacity: '70',
                overlayColor: '#333333'
            },
            fonts: {
                size: 14,
                family: 'BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif'
            }
        },
        privacyTrigger: {
            placement: 'bottomLeft',
            icon: 'https://img.usercentrics.eu/misc/icon-fingerprint.svg',
            iconColors: {
                background: '#0C27A0',
                icon: '#FFFFFF'
            }
        }
    },
    dark: {
        consentBanner: {
            layoutType: 'banner',
            theme: 'dark',
            generalColors: {
                backgroundColor: '#141414',
                linkColor: '#2EA7FF',
                tabColor: '#2EA7FF',
                textColor: '#FFFFFF',
                accentColor: '#333333'
            },
            buttonColors: {
                acceptButtonBackground: '#2EA7FF',
                acceptButtonText: '#141414',
                denyButtonBackground: '#2EA7FF',
                denyButtonText: '#141414',
                saveButtonBackground: '#2EA7FF',
                saveButtonText: '#141414',
                moreBtnBackground: '#FFFFFF',
                moreBtnText: '#2EA7FF'
            },
            toggleColors: {
                activeToggleBackground: '#2EA7FF',
                activeToggleIcon: '#FFFFFF',
                disabledToggleBackground: '#333333',
                disabledToggleIcon: '#FFFFFF',
                inactiveToggleBackground: '#FFFFFF',
                inactiveToggleIcon: '#141414'
            },
            advancedSettings: {
                useBackgroundShadow: true,
                backgroundOverlay: true,
                overlayOpacity: '70',
                overlayColor: '#333333'
            },
            fonts: {
                size: 14,
                family: 'BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif'
            }
        },
        privacyTrigger: {
            placement: 'bottomLeft',
            icon: 'https://img.usercentrics.eu/misc/icon-fingerprint.svg',
            iconColors: {
                background: '#0C27A0',
                icon: '#FFFFFF'
            }
        }
    }
};
