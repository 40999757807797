import { CustomEmbedModal } from '@components/ComponentItem/CustomEmbedModal/CustomEmbedModal';
import { useComponentsReport } from '@components/ComponentsReport/useComponentsReport';
import {
    Accordion,
    Box,
    Flex,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    MdSearch
} from '@usercentrics/cookiebot-ui-react';

import { ComponentGroup } from '../../types/general';
import { ComponentsCategory } from '../ComponentsCategory';

type Props = {
    heading: string;
    groupedComponents: ComponentGroup[];
};

export const ComponentsReport = (props: Props) => {
    const { groupedComponents, heading } = props;

    const {
        filteredComponents,
        search,
        setSearch,
        selectedCustomEmbed,
        setSelectedCustomEmbed,
        t,
        handleOnCustomEmbedModalClose
    } = useComponentsReport({ groupedComponents });

    return (
        <Box>
            <Flex mb={2} alignItems="center" justifyContent="space-between">
                <Heading as="h3">
                    <Flex align="center" gap={2}>
                        {heading}
                    </Flex>
                </Heading>
                <InputGroup w="auto">
                    <Input
                        placeholder={t('common.searchForComponents')}
                        onChange={(event) => setSearch(event.target.value)}
                        value={search}
                    />
                    <InputRightElement>
                        <Icon as={MdSearch} />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <Accordion
                allowMultiple
                reduceMotion
                size="lg"
                display="flex"
                flexDirection="column"
                gap={1}
            >
                {filteredComponents.map((group) => (
                    <ComponentsCategory
                        group={group}
                        key={`component-category-${group.name}`}
                        onCustomEmbedChange={setSelectedCustomEmbed}
                    />
                ))}
            </Accordion>
            <CustomEmbedModal
                component={selectedCustomEmbed}
                isOpen={!!selectedCustomEmbed}
                onClose={handleOnCustomEmbedModalClose}
            />
        </Box>
    );
};
