import { CustomEmbedModalFormState } from '@components/ComponentItem/CustomEmbedModal/types';
import { Box, Switch, Text } from '@usercentrics/cookiebot-ui-react';
import { ChangeEvent } from 'react';

type Props = {
    isActiveInBanner: CustomEmbedModalFormState['isActiveInBanner'];
    updateForm: (data: { id: string; value: boolean }) => void;
    t: (key: string) => string;
    sendEvent: (
        title: string,
        eventProperties?: Record<string, any> | undefined
    ) => void;
};

export const CustomEmbedLiveSwitch = ({
    isActiveInBanner,
    updateForm,
    t,
    sendEvent
}: Props) => {
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        sendEvent('Custom Embed Live Switch Changed', {
            value: event.target.checked
        });

        updateForm({
            id: 'isActiveInBanner',
            value: event.target.checked
        });
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            background="#F2F2F2"
            px="24px"
            py="24px"
            borderRadius="8px"
        >
            <Text fontWeight={600}>
                {t('componentsPage.customEmbeds.showInBanner')}
            </Text>
            <Switch
                size="md"
                isChecked={isActiveInBanner}
                onChange={handleOnChange}
            />
        </Box>
    );
};
