import { createBrowserRouter } from 'react-router-dom';

import { Admin } from './pages/Admin/Admin';
import { Appearance } from './pages/Appearance/Appearance';
import { Blocking } from './pages/Blocking';
import { Components } from './pages/Components';
import { Configuration } from './pages/Configuration/Configuration';
import { Content } from './pages/Content/Content';
import { Dashboard } from './pages/Dashboard';
import { Report } from './pages/Report';
import { Root } from './pages/Root';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: 'dashboard',
                element: <Dashboard />
            },
            {
                path: 'report',
                element: <Report />
            },
            {
                path: 'banner',
                children: [
                    {
                        path: 'appearance',
                        element: <Appearance />
                    },
                    {
                        path: 'content',
                        element: <Content />
                    },
                    {
                        path: 'components',
                        element: <Components />
                    },
                    {
                        path: 'blocking',
                        element: <Blocking />
                    }
                ]
            },
            {
                path: 'configuration',
                element: <Configuration />
            },
            {
                path: 'admin',
                element: <Admin />,
                loader: () => {
                    window.scrollTo(0, 0);

                    return null;
                }
            }
        ]
    }
]);
