import {
    Accordion,
    AccordionItem,
    AccordionPanel
} from '@usercentrics/cookiebot-ui-react';

import { usePagination } from '../../hooks';
import { Component, ComponentGroup } from '../../types/general';
import { ComponentTypeEnum } from '../../util/constants';
import { ComponentItem } from '../ComponentItem/ComponentItem';
import { Pagination } from '../Pagination/Pagination';
import { ComponentCategoryButton } from './ComponentCategoryButton';
import { ComponentCategoryDescription } from './ComponentCategoryDescription';

type Props = {
    group: ComponentGroup;
    onCustomEmbedChange: (component: Component) => void;
};

export const ComponentsCategory = (props: Props) => {
    const { group, onCustomEmbedChange } = props;
    const { name, items } = group;

    const isOtherServices = name === ComponentTypeEnum.otherServices;

    const isWixComponents = name === ComponentTypeEnum.wixStandardComponent;

    const isThirdPartyApps = name === ComponentTypeEnum.thirdPartyApp;

    const isCustomEmbeds = name === ComponentTypeEnum.customEmbeds;

    const {
        data,
        hasNext,
        hasPrevious,
        next,
        previous,
        last,
        first,
        from,
        to
    } = usePagination({ items, limit: 10 });

    return (
        <AccordionItem>
            <ComponentCategoryButton name={name} numOfItems={items.length} />
            <AccordionPanel borderTopWidth={1} borderColor="gray.100">
                <ComponentCategoryDescription
                    isWixComponents={isWixComponents}
                    isOtherServices={isOtherServices}
                    isThirdPartyApps={isThirdPartyApps}
                    isCustomEmbeds={isCustomEmbeds}
                />
                <Accordion
                    colorScheme="white"
                    size="lg"
                    allowMultiple
                    reduceMotion
                    pointerEvents={isOtherServices ? 'none' : 'auto'}
                >
                    <ComponentItem
                        data={data}
                        name={name}
                        isCustomEmbeds={isCustomEmbeds}
                        onCustomEmbedChange={onCustomEmbedChange}
                    />
                </Accordion>
                {items.length > 10 ? (
                    <Pagination
                        from={from}
                        to={to}
                        items={items}
                        hasNext={hasNext}
                        hasPrevious={hasPrevious}
                        first={first}
                        previous={previous}
                        next={next}
                        last={last}
                    />
                ) : null}
            </AccordionPanel>
        </AccordionItem>
    );
};
