import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Grid,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MdOutlineEdit,
    MdOutlineVisibility,
    MdOutlineVisibilityOff
} from 'react-icons/md';

import { useAmplitude } from '../../hooks';
import { Component } from '../../types/general';
import defaultClasses from '../ComponentsCategory/ComponentsCategory.module.css';
import { CustomEmbedContent } from './CustomEmbedContent';

type Props = {
    data: Component[];
    name: string;
    isCustomEmbeds: boolean;
    onCustomEmbedChange: (component: Component) => void;
};

export const ComponentItem = (props: Props) => {
    const { data, name, isCustomEmbeds, onCustomEmbedChange } = props;
    const { sendEvent } = useAmplitude();

    const { t } = useTranslation();

    const gridTemplateColumns = '2.5fr 1fr 1fr 1fr 30px';

    const grids = useMemo(() => {
        const columnName = t('reportPage.columnName');

        return {
            componentsPage: (
                <Grid
                    templateColumns={gridTemplateColumns}
                    px={4}
                    color="rgba(102, 102, 102, 1)"
                >
                    <Text>{columnName}</Text>
                    <Text>{t('common.show')}</Text>
                    <Text>{t('common.category')}</Text>
                    <Text />
                </Grid>
            ),
            customEmbeds: (
                <Grid
                    templateColumns={gridTemplateColumns}
                    px={4}
                    color="rgba(102, 102, 102, 1)"
                >
                    <Text>{columnName}</Text>
                    <Text>{t('common.show')}</Text>
                    <Text>{t('common.category')}</Text>
                    <Text>Action</Text>
                </Grid>
            )
        };
    }, [gridTemplateColumns, t]);

    const grid = useMemo(() => {
        if (isCustomEmbeds) {
            return grids.customEmbeds;
        }

        return grids.componentsPage;
    }, [grids.componentsPage, grids.customEmbeds, isCustomEmbeds]);

    const handleOnEditClick = useCallback(
        (event: MouseEvent<HTMLDivElement>, component: Component) => {
            sendEvent('Components Page - Custom Embeds Edit clicked', {
                component
            });
            event.preventDefault();
            onCustomEmbedChange(component);
        },
        [onCustomEmbedChange, sendEvent]
    );

    const getContent = useCallback(
        (component: Component) => ({
            componentsPage: (
                <>
                    <Text align="left">{component.name}</Text>
                    <Box
                        className={defaultClasses.capitalize}
                        textAlign="left"
                        fontWeight={400}
                    >
                        <Box display="flex" alignItems="center" gap="8px">
                            <MdOutlineVisibility size="24px" />
                            {t('common.yes')}
                        </Box>
                    </Box>
                    <Text
                        className={defaultClasses.capitalize}
                        align="left"
                        fontWeight={400}
                    >
                        {component.blockerCategory}
                    </Text>
                    <Text />
                </>
            ),
            customEmbeds: (
                <>
                    <Text align="left">{component.name}</Text>
                    <Box
                        className={defaultClasses.capitalize}
                        textAlign="left"
                        fontWeight={400}
                    >
                        <Box display="flex" alignItems="center" gap="8px">
                            {component.isActiveInBanner ? (
                                <MdOutlineVisibility size="24px" />
                            ) : (
                                <MdOutlineVisibilityOff size="24px" />
                            )}
                            {component.isActiveInBanner
                                ? t('common.yes')
                                : t('common.no')}
                        </Box>
                    </Box>
                    <Text
                        className={defaultClasses.capitalize}
                        align="left"
                        fontWeight={400}
                    >
                        {component.blockerCategory}
                    </Text>
                    <Box textAlign="left" fontWeight={600} color="#1032CF">
                        <Box
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            onClick={(event) =>
                                handleOnEditClick(event, component)
                            }
                        >
                            <MdOutlineEdit size="24px" />{' '}
                            <Box>{t('common.edit')}</Box>
                        </Box>
                    </Box>
                </>
            )
        }),
        [handleOnEditClick, t]
    );

    const renderContent = useCallback(
        (component: Component) => {
            if (isCustomEmbeds) {
                return getContent(component).customEmbeds;
            }

            return getContent(component).componentsPage;
        },
        [getContent, isCustomEmbeds]
    );

    return (
        <>
            {grid}
            <Stack spacing={1} mt={2}>
                {data.map((component) => (
                    <AccordionItem
                        key={`tracker-${name}-component-${component.name}`}
                        borderRadius={6}
                        boxShadow="3px 2px 4px 0px #bbbbbb"
                        onClick={() =>
                            sendEvent(
                                'Components page - Component expanded/collapsed',
                                {
                                    component: component.name
                                }
                            )
                        }
                    >
                        <AccordionButton
                            display="grid"
                            gridTemplateColumns={gridTemplateColumns}
                            color="black"
                            _expanded={{
                                color: 'black',
                                borderBottomWidth: '1px',
                                borderColor: 'gray.100'
                            }}
                            _hover={{
                                color: 'black'
                            }}
                        >
                            {renderContent(component)}
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                            {isCustomEmbeds ? (
                                <CustomEmbedContent component={component} />
                            ) : (
                                <Text>{component.description}</Text>
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Stack>
        </>
    );
};
