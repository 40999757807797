import { CustomEmbedModalFormState } from '@components/ComponentItem/CustomEmbedModal/types';
import { Box, Text, Textarea } from '@usercentrics/cookiebot-ui-react';

type Props = {
    description: CustomEmbedModalFormState['description'];
    updateForm: (data: { id: string; value: string }) => void;
    t: (key: string) => string;
    sendEvent: (
        title: string,
        eventProperties?: Record<string, any> | undefined
    ) => void;
};

export const CustomEmbedDescription = ({
    description,
    updateForm,
    t,
    sendEvent
}: Props) => (
    <Box>
        <Text fontWeight={600}>
            {t('componentsPage.customEmbeds.descInBanner')}
        </Text>
        <Textarea
            value={description}
            onChange={(event) => {
                updateForm({
                    id: 'description',
                    value: event.target.value
                });
            }}
            onBlur={(event) =>
                sendEvent('Custom Embed Description Changed', {
                    value: event.target.value
                })
            }
            placeholder={t(
                'componentsPage.customEmbeds.descInBannerPlaceholder'
            )}
        />
    </Box>
);
