import {
    Box,
    Select,
    SelectButton,
    SelectList,
    SelectOption
} from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { regionalSettingsList } from '../../../pages/Configuration/configurationUtils';
import { ConfigurationProps } from '../../../pages/Configuration/types/Configuration.types';

export const CcpaRegionSelection = (props: ConfigurationProps) => {
    const { configuration, saveConfiguration } = props;
    const { t } = useTranslation('translation', {
        keyPrefix: 'configurationPage.textLabels'
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleCcpaRegionChange = (region: string) => {
        setIsLoading(true);

        saveConfiguration({
            ...configuration,
            ccpa: { ...configuration.ccpa, region }
        }).finally(() => setIsLoading(false));
    };

    return (
        <Box display="flex" alignItems="center" gap={4}>
            <Select
                width="500px"
                value={configuration.ccpa?.region}
                size="sm"
                onChange={(e) => handleCcpaRegionChange(e.target.value)}
                isDisabled={isLoading}
            >
                <SelectButton width="500px">
                    {t('selectRegionalSetting')}
                </SelectButton>
                <SelectList width="500px">
                    {regionalSettingsList.map((option) => (
                        <SelectOption value={option.value} key={option.value}>
                            {option.label}
                        </SelectOption>
                    ))}
                </SelectList>
            </Select>
        </Box>
    );
};
