import { Component, ComponentGroup, KeyofType } from '../types/general';
import { ComponentTypeEnum, componentTypeSortOrder } from './constants';

export const groupComponentsBy = (
    arr: Component[],
    property: KeyofType<Component, string> = 'type'
): ComponentGroup[] => {
    const groupedAndSorted = arr.reduce((result, component) => {
        const name = (ComponentTypeEnum as any)[component[property]];
        const existingGroup = result.find(
            (group: ComponentGroup) => group.name === name
        );

        if (!existingGroup) {
            result.push({
                name,
                type: component[property],
                items: [component]
            });
        } else {
            existingGroup.items.push(component);
        }

        return result;
    }, [] as ComponentGroup[]);
    groupedAndSorted.sort(
        (groupA, groupB) =>
            componentTypeSortOrder[groupA.type] -
            componentTypeSortOrder[groupB.type]
    );

    return groupedAndSorted;
};
