import { Accordion, Box, Stack } from '@usercentrics/cookiebot-ui-react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentBannerFormState } from '../../pages/Appearance/types/consentBanner.type';
import { UpdateFormInput } from '../../types/general';
import { ColorsAccordionWrapper } from '../ColorsAccordionWrapper/ColorsAccordionWrapper';
import { FontsAccordionWrapper } from '../FontsAccordionWrapper/FontsAccordionWrapper';
import { ConsentBannerBackgroundOverlay } from './AdvancedSettings/ConsentBannerBackgroundOverlay';
import { ConsentBannerBackgroundShadow } from './AdvancedSettings/ConsentBannerBackgroundShadow';
import { ConsentBannerButtonsColors } from './ConsentBannerButtonsColors/ConsentBannerButtonsColors';
import { ConsentBannerFonts } from './ConsentBannerFonts/ConsentBannerFonts';
import { ConsentBannerToggleColors } from './ConsentBannerToggleColors/ConsentBannerToggleColors';
import { ContentBannerGeneralColors } from './ContentBannerGeneralColors/ContentBannerGeneralColors';
import { ContentBannerTheme } from './ContentBannerTheme/ContentBannerTheme';

type LayoutTabTypes = {
    consentBannerFormState: ConsentBannerFormState;
    setConsentBannerFormState: Dispatch<SetStateAction<ConsentBannerFormState>>;
    updateConsentBannerForm: (payload: UpdateFormInput) => void;
};

export const ConsentBannerTab = ({
    consentBannerFormState,
    setConsentBannerFormState,
    updateConsentBannerForm
}: LayoutTabTypes) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.consentBanner'
    });

    return (
        <Box>
            <Stack spacing="40px">
                <Stack spacing="32px">
                    <ContentBannerTheme
                        theme={consentBannerFormState.theme}
                        setConsentBannerFormState={setConsentBannerFormState}
                    />
                    {consentBannerFormState.theme === 'custom' ? (
                        <Accordion
                            allowMultiple={false}
                            defaultIndex={0}
                            reduceMotion
                        >
                            <Stack spacing="16px">
                                <ColorsAccordionWrapper
                                    title={t('generalColors.title')}
                                >
                                    <ContentBannerGeneralColors
                                        updateConsentBannerForm={
                                            updateConsentBannerForm
                                        }
                                        colorsState={
                                            consentBannerFormState.generalColors
                                        }
                                    />
                                </ColorsAccordionWrapper>
                                <ColorsAccordionWrapper
                                    title={t('buttonColors.title')}
                                >
                                    <ConsentBannerButtonsColors
                                        updateForm={updateConsentBannerForm}
                                        colors={
                                            consentBannerFormState.buttonColors
                                        }
                                    />
                                </ColorsAccordionWrapper>
                                <ColorsAccordionWrapper
                                    title={t('toggleColors.title')}
                                >
                                    <ConsentBannerToggleColors
                                        updateForm={updateConsentBannerForm}
                                        togglesFormState={
                                            consentBannerFormState.toggleColors
                                        }
                                    />
                                </ColorsAccordionWrapper>
                                <FontsAccordionWrapper
                                    title={t('bannerFonts.title')}
                                >
                                    <ConsentBannerFonts
                                        updateForm={updateConsentBannerForm}
                                        fontsFormState={
                                            consentBannerFormState.fonts
                                        }
                                    />
                                </FontsAccordionWrapper>
                                <ColorsAccordionWrapper
                                    title={t('advancedSettings.title')}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="start"
                                        gap="24px"
                                    >
                                        <ConsentBannerBackgroundOverlay
                                            advancedSettingsState={
                                                consentBannerFormState.advancedSettings
                                            }
                                            updateConsentBannerForm={
                                                updateConsentBannerForm
                                            }
                                        />
                                        <ConsentBannerBackgroundShadow
                                            onChange={(event: any) =>
                                                updateConsentBannerForm({
                                                    formSection:
                                                        'advancedSettings',
                                                    id: 'useBackgroundShadow',
                                                    value: event.target.checked
                                                })
                                            }
                                            isChecked={
                                                consentBannerFormState
                                                    .advancedSettings
                                                    .useBackgroundShadow
                                            }
                                        />
                                    </Box>
                                </ColorsAccordionWrapper>
                            </Stack>
                        </Accordion>
                    ) : null}
                </Stack>
            </Stack>
        </Box>
    );
};
