import { Box, Button, Text } from '@usercentrics/cookiebot-ui-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigurationProps } from '../../../pages/Configuration/types/Configuration.types';

export const ResurfaceBanner = (props: ConfigurationProps) => {
    const { configuration, saveConfiguration } = props;
    const lastResurfaced = configuration?.lastForcedResurfaceTimestamp;

    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation('translation', {
        keyPrefix: 'configurationPage.cmpSettings'
    });

    const formattedDate = useMemo(() => {
        if (!lastResurfaced) {
            return '';
        }

        const date = new Date(lastResurfaced * 1000);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes} UTC`;
    }, [lastResurfaced]);

    const handleClick = () => {
        setIsLoading(true);
        const timestamp = Date.now().toString().slice(0, -3);

        saveConfiguration({
            ...configuration,
            lastForcedResurfaceTimestamp: Number(timestamp)
        }).finally(() => setIsLoading(false));
    };

    return (
        <Box display="flex" p={4} gap={16} alignItems="center">
            <Box display="flex" flexDir="column" gap={1}>
                <Text fontSize={18} fontWeight={600} mb={2}>
                    {t('resurface.title')}
                </Text>
                <Text>{t('resurface.description')}</Text>
                {formattedDate ? (
                    <Text fontSize="small">
                        {t('resurface.lastResurface', { date: formattedDate })}
                    </Text>
                ) : null}
            </Box>
            <Button onClick={handleClick} isDisabled={isLoading}>
                {t('resurface.action')}
            </Button>
        </Box>
    );
};
