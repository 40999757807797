import { useToast } from '@chakra-ui/react';
import { CustomEmbedModalFormState } from '@components/ComponentItem/CustomEmbedModal/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateCustomComponent } from '../../../api/api';
import { useAmplitude } from '../../../hooks';
import { useForm } from '../../../hooks/useForm';
import { useAuthStore, useReportStore } from '../../../stores';
import { Component } from '../../../types/general';

const defaultFormState: CustomEmbedModalFormState = {
    title: '',
    description: '',
    selectedUrls: [],
    isActiveInBanner: false
};

export const useCustomEmbedModal = ({
    component,
    onClose
}: {
    component: Component | null;
    onClose: () => void;
}) => {
    const { t } = useTranslation();
    const { unknownComponents } = useReportStore();
    const { appInstanceId, domain } = useAuthStore();
    const queryClient = useQueryClient();
    const toast = useToast();
    const {
        formState,
        update: updateForm,
        setFormState
    } = useForm<CustomEmbedModalFormState>(defaultFormState);
    const { sendEvent: sendAmplitudeEvent } = useAmplitude();

    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [allUrls, setAllUrls] = useState<string[]>([]);

    const { mutateAsync: updateCustomEmbed } = useMutation({
        mutationFn: updateCustomComponent,
        cacheTime: 0
    });

    const sendEvent = useCallback(
        (title: string, eventProperties?: Record<string, any> | undefined) =>
            sendAmplitudeEvent(title, {
                ...eventProperties,
                component
            }),
        [component, sendAmplitudeEvent]
    );

    useEffect(() => {
        if (component) {
            setFormState({
                title: component.title ?? '',
                description: component.description,
                selectedUrls: component.mappedUrls ?? [],
                isActiveInBanner: component.isActiveInBanner ?? false
            });

            setAllUrls([...unknownComponents, ...(component.mappedUrls ?? [])]);
        }
    }, [component, setFormState, unknownComponents]);

    const filteredUrls = useMemo(
        () =>
            allUrls.filter((url) =>
                url.toLowerCase().includes(search.toLowerCase())
            ),
        [allUrls, search]
    );

    const handleSave = async () => {
        sendEvent('Custom Embed Modal - Save Button Clicked');
        setIsLoading(true);

        if (component?.id) {
            try {
                await updateCustomEmbed({
                    id: component.id,
                    title: formState.title,
                    description: formState.description,
                    mappedUrls: formState.selectedUrls,
                    isActiveInBanner: formState.isActiveInBanner,
                    appInstanceId
                });
                await queryClient.invalidateQueries([
                    'getCustomComponents',
                    appInstanceId
                ]);
                await queryClient.invalidateQueries([
                    'scanReport',
                    { appInstanceId, domainUrl: domain }
                ]);
                onClose();
                toast({
                    description: t('componentsPage.updateSuccessMessage'),
                    status: 'info',
                    duration: 5000,
                    isClosable: true
                });
            } catch (error) {
                toast({
                    description: t('componentsPage.updateErrorMessage'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            }
        }

        setIsLoading(false);
    };

    return {
        t,
        filteredUrls,
        isLoading,
        formState,
        updateForm,
        search,
        handleSave,
        setSearch,
        sendEvent
    };
};
