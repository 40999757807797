import { Divider } from '@chakra-ui/react';
import {
    Box,
    Input,
    Stack,
    Text,
    Textarea
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { Component } from '../../types/general';

type Props = {
    component: Component;
};

export const CustomEmbedContent = ({ component }: Props) => {
    const { t } = useTranslation();

    return (
        <Stack spacing="24px">
            <Box>
                <Text fontWeight={600}>
                    {t('componentsPage.customEmbeds.titleInBanner')}
                </Text>
                <Input value={component.title} isDisabled />
            </Box>
            <Box>
                <Text fontWeight={600}>
                    {' '}
                    {t('componentsPage.customEmbeds.descInBanner')}
                </Text>
                <Textarea value={component.description} isDisabled />
            </Box>
            <Divider />
            {component.mappedUrls ? (
                <Box>
                    <Text fontWeight={600}>
                        {t('componentsPage.customEmbeds.mappedUrls')}
                    </Text>
                    {component.mappedUrls?.map((url) => (
                        <Text fontWeight={600} color="#1032CF" key={url}>
                            {url}
                        </Text>
                    ))}
                </Box>
            ) : null}
        </Stack>
    );
};
