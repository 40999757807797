import { Divider } from '@chakra-ui/react';
import {
    Box,
    ColorPicker,
    Input,
    Stack,
    Switch,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { ConsentBannerFormState } from '../../../pages/Appearance/types/consentBanner.type';
import { useAppConfigStore } from '../../../stores';
import { UpdateFormInput } from '../../../types/general';

type Props = {
    advancedSettingsState: ConsentBannerFormState['advancedSettings'];
    updateConsentBannerForm: (payload: UpdateFormInput) => void;
};

export const ConsentBannerBackgroundOverlay = ({
    updateConsentBannerForm,
    advancedSettingsState
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.consentBanner.advancedSettings'
    });
    const { isScreenSmall } = useAppConfigStore();

    const onChange = (event: any) => {
        updateConsentBannerForm({
            formSection: 'advancedSettings',
            id: 'backgroundOverlay',
            value: event.target.checked
        });
    };

    const isChecked = advancedSettingsState.backgroundOverlay;

    const onOpacityChange = (event: any) => {
        updateConsentBannerForm({
            formSection: 'advancedSettings',
            id: 'overlayOpacity',
            value: event.target.value
        });
    };

    const onColorChange = (event: any) => {
        updateConsentBannerForm({
            formSection: 'advancedSettings',
            id: 'overlayColor',
            value: event.target.value
        });
    };

    return (
        <Box
            backgroundColor="#ffffff"
            px="24px"
            py="16px"
            width="50%"
            borderRadius="6px"
        >
            <Stack spacing="16px">
                <Box display="flex" justifyContent="space-between">
                    <Text
                        fontSize={15}
                        fontWeight={600}
                        display="flex"
                        gap={2}
                        alignItems="center"
                    >
                        {t('backgroundOverlay')}
                    </Text>
                    <Box>
                        <Switch
                            isChecked={isChecked as boolean}
                            onChange={onChange}
                        />
                    </Box>
                </Box>
                {isChecked ? (
                    <>
                        <Divider />
                        <Box
                            display="flex"
                            gap={2}
                            flexDirection={isScreenSmall ? 'column' : 'row'}
                        >
                            <Box width="100%">
                                <Text fontSize={14} fontWeight={600}>
                                    {t('color')}
                                </Text>
                                <Box display="flex" alignItems="center">
                                    <ColorPicker
                                        value={
                                            advancedSettingsState.overlayColor as string
                                        }
                                        onChange={onColorChange}
                                    />
                                    <Input
                                        value={
                                            advancedSettingsState.overlayColor as string
                                        }
                                        onChange={onColorChange}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Text fontSize={14} fontWeight={600}>
                                    {t('opacity')} (%)
                                </Text>
                                <Input
                                    type="number"
                                    value={
                                        advancedSettingsState.overlayOpacity as string
                                    }
                                    onChange={onOpacityChange}
                                />
                            </Box>
                        </Box>
                    </>
                ) : null}
            </Stack>
        </Box>
    );
};
