export const BaseUrl = `${import.meta.env.VITE_API_BASE_URL}`;

export const textHeaders = {
    Accept: 'text/plain',
    'Content-Type': 'application/json'
};

export const callApi = (
    input: RequestInfo | URL,
    init?: RequestInit
): Promise<Response> =>
    new Promise((resolve, reject) => {
        fetch(input, init)
            .then((resp) => {
                if (!resp.ok) {
                    reject(resp);
                } else {
                    resolve(resp);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
