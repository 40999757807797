import { t } from 'i18next';

export const regionalSettingsList = [
    {
        label: t('configurationPage.legal.region.usCa'),
        value: 'US_CA_ONLY'
    },
    { label: t('configurationPage.legal.region.us'), value: 'US' },
    { label: t('configurationPage.legal.region.all'), value: 'ALL' }
];
