import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAmplitude } from '../../hooks';
import { Component, ComponentGroup } from '../../types/general';

type Props = {
    groupedComponents: ComponentGroup[];
};

export const useComponentsReport = ({ groupedComponents }: Props) => {
    const { t } = useTranslation();
    const { sendEvent } = useAmplitude();

    const [search, setSearch] = useState('');
    const [selectedCustomEmbed, setSelectedCustomEmbed] =
        useState<Component | null>(null);

    const filteredComponents = useMemo(() => {
        if (search) {
            return groupedComponents.map((category) => ({
                ...category,
                items: category.items.filter((component) =>
                    component.name.toLowerCase().includes(search.toLowerCase())
                )
            }));
        }

        return groupedComponents;
    }, [groupedComponents, search]);

    const handleOnCustomEmbedModalClose = useCallback(() => {
        sendEvent('Components page - Custom Embed Modal Closed', {
            component: selectedCustomEmbed
        });
        setSelectedCustomEmbed(null);
    }, [selectedCustomEmbed, sendEvent]);

    return {
        filteredComponents,
        search,
        setSearch,
        selectedCustomEmbed,
        setSelectedCustomEmbed,
        t,
        handleOnCustomEmbedModalClose
    };
};
