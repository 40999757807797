import defaultClasses from '@components/ComponentsCategory/ComponentsCategory.module.css';
import { gridTemplateColumns } from '@components/ReportIdentifiedComponents/constants';
import { IgnoreRecommendationsButton } from '@components/ReportIdentifiedComponents/IgnoreRecommendationsButton';
import {
    stateColorMap,
    stateTranslationMap
} from '@components/ReportIdentifiedComponents/types';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    MdErrorOutline,
    Stack,
    Tag,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAmplitude } from '../../hooks';
import { Component } from '../../types/general';
import {
    Colors,
    ComponentTypeEnum,
    recommendedActionsMap
} from '../../util/constants';

type Props = {
    component: Component;
};

export const ReportIdentifiedComponent = ({ component }: Props) => {
    const { t } = useTranslation();
    const { sendEvent } = useAmplitude();

    const recommendedActionError = component.recommendedAction?.error;

    return (
        <Accordion
            allowToggle
            reduceMotion
            boxShadow="0 4px 6px 1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
            border="1px solid #E2E8F0"
            borderRadius="8px"
            overflowWrap="anywhere"
        >
            <Box bg="transparent">
                <AccordionItem
                    bg="transparent"
                    _hover={{}}
                    onClick={() =>
                        sendEvent(
                            'Scan report - Component expanded/collapsed',
                            {
                                component: component.name
                            }
                        )
                    }
                >
                    <AccordionButton
                        display="grid"
                        gridTemplateColumns={gridTemplateColumns}
                        bg="transparent"
                        gap="8px"
                        px="24px"
                        py="16px"
                        _hover={{
                            color: 'black'
                        }}
                        _expanded={{
                            color: 'black'
                        }}
                    >
                        <Box fontWeight="700" textAlign="left">
                            {component.name}
                        </Box>
                        <Box textAlign="left" fontWeight={500}>
                            {t((ComponentTypeEnum as any)[component.type])}
                        </Box>
                        <Box
                            className={defaultClasses.capitalize}
                            textAlign="left"
                            fontWeight={500}
                        >
                            {component.recommendedCategory}
                        </Box>
                        <Box textAlign="left">
                            <Tag background={stateColorMap[component.state]}>
                                {t(stateTranslationMap[component.state])}
                            </Tag>
                        </Box>

                        <Box display="flex" justifyContent="flex-end">
                            <Flex>
                                <Box p={0}>
                                    <AccordionIcon />
                                </Box>
                            </Flex>
                        </Box>
                    </AccordionButton>

                    <AccordionPanel
                        borderTopWidth={1}
                        borderColor="gray.100"
                        m={0}
                        p={0}
                    >
                        <Box px="24px" py="16px">
                            <Box
                                display="grid"
                                gridTemplateColumns="1fr"
                                alignItems="center"
                                gap="16px"
                            >
                                <Stack spacing="16px">
                                    {!component.recommendationIgnored &&
                                    !component.blockedPriorConsent &&
                                    component.hasConflict ? (
                                        <Box
                                            bg={
                                                recommendedActionError
                                                    ? Colors.Error
                                                    : '#FDECCF'
                                            }
                                            py="16px"
                                            px="24px"
                                            borderRadius="8px"
                                            display="flex"
                                            gap="8px"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Box
                                                display="flex"
                                                gap="8px"
                                                alignItems="center"
                                            >
                                                <MdErrorOutline
                                                    fontSize={24}
                                                    color={
                                                        recommendedActionError
                                                            ? '#fff'
                                                            : '#F6A724'
                                                    }
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="4px"
                                                    alignItems="center"
                                                >
                                                    {!recommendedActionError && (
                                                        <Text>
                                                            {t(
                                                                'reportPage.weRecommendYouTo'
                                                            )}
                                                            :
                                                        </Text>
                                                    )}
                                                    <Text
                                                        color={
                                                            recommendedActionError
                                                                ? '#fff'
                                                                : '#1032CF'
                                                        }
                                                        fontWeight={700}
                                                    >
                                                        {recommendedActionError ? (
                                                            t(
                                                                'reportPage.recommendedAction.error'
                                                            )
                                                        ) : (
                                                            <Trans
                                                                i18nKey={
                                                                    recommendedActionsMap[
                                                                        component
                                                                            .recommendedAction
                                                                            .value
                                                                    ]
                                                                }
                                                                components={{
                                                                    underlineLink:
                                                                        (
                                                                            <Link
                                                                                to="/banner/blocking"
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                            />
                                                                        )
                                                                }}
                                                            />
                                                        )}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ) : null}
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        {component.description}
                                        {(!recommendedActionError &&
                                            !component.recommendationIgnored &&
                                            !component.blockedPriorConsent &&
                                            component.hasConflict) ||
                                        component.recommendationIgnored ? (
                                            <Box>
                                                <IgnoreRecommendationsButton
                                                    templateId={
                                                        component.templateId
                                                    }
                                                    recommendationIgnored={
                                                        component.recommendationIgnored
                                                    }
                                                />
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Box>
        </Accordion>
    );
};
