import { CustomEmbedsSectionDetails } from '@components/SectionDetails/CustomEmbedsSectionDetails';
import { Box } from '@usercentrics/cookiebot-ui-react';

import { StandardComponentsSectionDetails } from '../SectionDetails/StandardComponentsSectionDetails';
import { ThirdPartyAppsSectionDetails } from '../SectionDetails/ThirdPartyAppsSectionDetails';

type Props = {
    isWixComponents: boolean;
    isThirdPartyApps: boolean;
    isOtherServices: boolean;
    isCustomEmbeds: boolean;
};

export const ComponentCategoryDescription = ({
    isWixComponents,
    isThirdPartyApps,
    isOtherServices,
    isCustomEmbeds
}: Props) => (
    <>
        {isWixComponents ? (
            <Box mb="24px">
                <StandardComponentsSectionDetails />
            </Box>
        ) : null}
        {isThirdPartyApps ? (
            <Box mb="24px">
                <ThirdPartyAppsSectionDetails />
            </Box>
        ) : null}
        {isOtherServices ? (
            <Box mb="24px">
                <ThirdPartyAppsSectionDetails />
            </Box>
        ) : null}

        {isCustomEmbeds ? (
            <Box mb="24px">
                <CustomEmbedsSectionDetails />
            </Box>
        ) : null}
    </>
);
