import { SvgProps } from '../../types/general';

export const SecuritySvg = ({
    fill = '#0C27A0',
    iconColor = '#fff',
    width = '71px',
    height = '71px'
}: SvgProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 71 71"
        height={height as string}
        width={width as string}
    >
        <g id="img/security-shield-rounded" fill="none" fillRule="evenodd">
            <circle cx="35.5" cy="35.5" r="35.5" fill={fill as string} />
            <g transform="translate(22.1875 18.6375)">
                <path
                    d="M12.114375.9854948L1.76020833 5.58570311C.69520833 6.05903646 0 7.12403646 0 8.29257812v6.95208338c0 8.209375 5.68 15.88625 13.3125 17.75 7.6325-1.86375 13.3125-9.540625 13.3125-17.75V8.29257812c0-1.16854166-.6952083-2.23354166-1.7602083-2.706875L14.510625.98549479c-.754375-.34020833-1.641875-.34020833-2.39625 0zM9.30395833 23.0694531l-3.83104166-3.8310416c-.27697792-.276355-.43263496-.6515469-.43263496-1.0428125 0-.3912657.15565704-.7664576.43263496-1.0428125.576875-.576875 1.50875-.576875 2.085625 0l2.79562503 2.7808333 8.6975-8.6975c.576875-.576875 1.50875-.576875 2.085625 0 .576875.576875.576875 1.50875 0 2.085625l-9.7477084 9.7477083c-.5620833.576875-1.50874997.576875-2.08562497 0z"
                    id="shield-round-a"
                    fill={iconColor as string}
                />
            </g>
        </g>
    </svg>
);
