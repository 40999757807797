import { ResetScansCount } from '@components/ResetScansCount';
import { ScanAnySite } from '@components/ScanAnySite';
import { ScanStatusStartButton } from '@components/ScanStatus/ScanStatusStartButton';
import { Box, Divider, Flex, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useReportStore } from '../../stores';

export const Admin = () => {
    const { t } = useTranslation();
    const { status } = useReportStore();

    return (
        <Box w="100%">
            <Text fontSize={21} fontWeight={600} mb="16px">
                {t('adminPage.title')}
            </Text>
            <Box
                bg="gray.50"
                borderRadius="lg"
                py={6}
                px={12}
                w="100%"
                alignSelf="flex-start"
            >
                <Flex direction="column" gap={4}>
                    <Text fontSize="3xl" fontWeight="bold">
                        {t('adminPage.scanning.title')}
                    </Text>
                    <Divider />
                    <ScanStatusStartButton status={status} />
                    <ScanAnySite />
                    <ResetScansCount />
                </Flex>
            </Box>
        </Box>
    );
};
