import { Divider } from '@chakra-ui/react';
import { BannerSection } from '@components/Navigation/BannerSection';
import { SupportSection } from '@components/Navigation/SupportSection';
import {
    MdAdminPanelSettings,
    MdFingerprint,
    MdOutlineDashboard,
    MdSettings,
    Nav
} from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdminStore, useAppConfigStore, useReportStore } from '../../stores';
import { StatusEnum } from '../../util/constants';
import { NavLink } from '../NavLink';

export const Navigation = () => {
    const { t } = useTranslation();
    const { status } = useReportStore();
    const [highlightParent, setHighlightParent] = useState<string | null>(null);
    const { appError } = useAppConfigStore();
    const { isAdmin } = useAdminStore();

    return (
        <Nav>
            <NavLink
                href="/dashboard"
                label={t('routes.dashboard')}
                icon={<MdOutlineDashboard />}
                setHighlightParent={setHighlightParent}
            />
            <NavLink
                disabled={status !== StatusEnum.Done}
                href="/report"
                label={t('routes.report')}
                icon={<MdFingerprint />}
                setHighlightParent={setHighlightParent}
            />
            <Divider />
            <BannerSection
                setHighlightParent={setHighlightParent}
                status={status}
                appError={appError}
                highlightParent={highlightParent}
            />
            <Divider />
            <SupportSection
                setHighlightParent={setHighlightParent}
                status={status}
            />
            <Divider />
            <NavLink
                href="/configuration"
                label={t('routes.configuration')}
                icon={<MdSettings />}
                setHighlightParent={setHighlightParent}
            />
            <div>
                {isAdmin && (
                    <>
                        <Divider />
                        <NavLink
                            href="/admin"
                            label={t('routes.admin')}
                            icon={<MdAdminPanelSettings />}
                            setHighlightParent={setHighlightParent}
                        />
                    </>
                )}
            </div>
        </Nav>
    );
};
