import { ShieldBlackSvg } from '@assets/ShieldBlackSvg';
import { Switch } from '@chakra-ui/react';
import styles from '@components/BannerPreview/BannerPreview.module.css';
import { Settings } from '@components/BannerPreview/types';
import { getContrastingColor } from '@components/BannerPreview/utils';
import { Box, Button, Text } from '@usercentrics/cookiebot-ui-react';
import { CSSProperties } from 'react';

type Props = {
    settings: Settings;
};

export const BannerBottomPreview = ({ settings }: Props) => {
    const {
        generalColors,
        advancedSettings,
        buttonColors,
        toggleColors,
        fonts
    } = settings;

    return (
        <Box
            display="grid"
            gridTemplateColumns="minmax(20%, 220px) 1fr"
            w="100%"
            h="auto"
            bg={generalColors.backgroundColor!}
            zIndex={1}
            borderRadius="8px"
            boxShadow={
                advancedSettings.useBackgroundShadow
                    ? '0 32px 68px rgba(0, 0, 0, .3)'
                    : ''
            }
        >
            <Box p="24px">
                <Box
                    w="100%"
                    h="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Box
                        maxWidth="192px"
                        h="172px"
                        display="block"
                        alignContent="center"
                        alignSelf="center"
                    >
                        <ShieldBlackSvg
                            fill={getContrastingColor(
                                settings.generalColors.backgroundColor!
                            )}
                        />
                    </Box>
                    <Box>
                        <Text
                            style={{
                                color: getContrastingColor(
                                    settings.generalColors.backgroundColor!
                                )
                            }}
                            fontSize={fonts.size}
                            fontFamily={fonts.family}
                            textAlign="center"
                        >
                            Powered by Usercentrics Consent Management
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Box p="24px">
                <Box
                    w="100%"
                    h="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    gap="8px"
                >
                    <Text
                        fontWeight={600}
                        color={generalColors.textColor!}
                        fontSize={fonts.size}
                        fontFamily={fonts.family}
                    >
                        Title text
                    </Text>
                    <Text
                        color={generalColors.textColor!}
                        className={styles.overflowText}
                        fontSize={fonts.size}
                        fontFamily={fonts.family}
                        maxH={100}
                    >
                        This site uses third-party website tracking technologies
                        to provide and continually improve our services, and to
                        display advertisements according to users interests. I
                        agree and may revoke or change my consent at any time
                        with effect for the future.
                    </Text>
                    <Box
                        color={generalColors.linkColor!}
                        fontWeight={600}
                        fontSize={fonts.size}
                        fontFamily={fonts.family}
                    >
                        Link
                    </Box>
                    <Box display="flex" gap="16px">
                        <Box display="flex" alignItems="center" gap="8px">
                            <Text
                                fontSize={fonts.size}
                                fontFamily={fonts.family}
                                color={generalColors.textColor!}
                                fontWeight={600}
                            >
                                Disabled
                            </Text>
                            <Switch
                                isDisabled
                                isChecked
                                className={styles.customDisabledSwitch}
                                style={
                                    {
                                        '--background-color':
                                            toggleColors.disabledToggleBackground,
                                        '--icon-color':
                                            toggleColors.disabledToggleIcon
                                    } as CSSProperties
                                }
                            />
                        </Box>
                        <Box display="flex" alignItems="center" gap="8px">
                            {[1, 2].map((index) => (
                                <>
                                    <Text
                                        color={generalColors.textColor!}
                                        fontWeight={600}
                                        fontSize={fonts.size}
                                        fontFamily={fonts.family}
                                    >
                                        Category
                                    </Text>
                                    <Switch
                                        isChecked={index === 1}
                                        className={styles.customSwitch}
                                        style={
                                            {
                                                '--active-background-color':
                                                    toggleColors.activeToggleBackground,
                                                '--active-icon-color':
                                                    toggleColors.activeToggleIcon,
                                                '--inactive-background-color':
                                                    toggleColors.inactiveToggleBackground,
                                                '--inactive-icon-color':
                                                    toggleColors.inactiveToggleIcon
                                            } as CSSProperties
                                        }
                                    />
                                </>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        w="100%"
                        gap="8px"
                        justifyContent="space-between"
                    >
                        <Button
                            w="100%"
                            bg={buttonColors.saveButtonBackground!}
                            _hover={{}}
                        >
                            <Text
                                color={buttonColors.saveButtonText!}
                                fontSize={fonts.size}
                                fontFamily={fonts.family}
                            >
                                Save Settings
                            </Text>
                        </Button>
                        <Button
                            w="100%"
                            bg={buttonColors.denyButtonBackground!}
                            _hover={{}}
                        >
                            <Text
                                color={buttonColors.denyButtonText!}
                                fontSize={fonts.size}
                                fontFamily={fonts.family}
                            >
                                Deny
                            </Text>
                        </Button>
                        <Button
                            w="100%"
                            bg={buttonColors.acceptButtonBackground!}
                            _hover={{}}
                        >
                            <Text
                                color={buttonColors.acceptButtonText!}
                                fontSize={fonts.size}
                                fontFamily={fonts.family}
                            >
                                Accept All
                            </Text>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
