import { CmpSettingsTab } from '@components/Configuration/CmpSettings';
import { LegalTab } from '@components/Configuration/Legal';
import { Loader } from '@components/Loader';
import {
    Box,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfiguration } from './useConfiguration';

export const Configuration = () => {
    const { configuration, saveConfiguration, isGetLoading } =
        useConfiguration();

    const { t } = useTranslation();

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabsChange = (index: number) => {
        setTabIndex(index);
    };

    return (
        <>
            <Text fontSize="60px" fontWeight="800">
                {t('configurationPage.title')}
            </Text>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
                <TabList
                    py={4}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex">
                        <Tab>{t('configurationPage.legal.tabTitle')}</Tab>
                        <Tab>{t('configurationPage.cmpSettings.tabTitle')}</Tab>
                    </Box>
                </TabList>

                {configuration ? (
                    <TabPanels>
                        <TabPanel>
                            <LegalTab
                                configuration={configuration}
                                saveConfiguration={saveConfiguration}
                            />
                        </TabPanel>
                        <TabPanel>
                            <CmpSettingsTab
                                configuration={configuration}
                                saveConfiguration={saveConfiguration}
                            />
                        </TabPanel>
                    </TabPanels>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        h="100%"
                        my={10}
                    >
                        {isGetLoading ? (
                            <Loader />
                        ) : (
                            <Text fontSize="20px" fontWeight="600">
                                {t('configurationPage.loadError')}
                            </Text>
                        )}
                    </Box>
                )}
            </Tabs>
        </>
    );
};
