import { SectionDetails } from '@components/SectionDetails/SectionDetails';
import { useTranslation } from 'react-i18next';

export const CustomEmbedsSectionDetails = () => {
    const { t } = useTranslation();

    return (
        <SectionDetails
            title={t('componentsPage.customEmbedsSectionDetailsTitle')}
            paragraph1={t('componentsPage.customEmbedsSectionDetailsDesc')}
        />
    );
};
