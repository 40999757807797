import { PlacementSvg } from '@assets/PlacementSvg';
import {
    Box,
    Label,
    RadioCard,
    RadioCardBody,
    RadioCardGroup,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { PrivacyTriggerFormState } from '../../../pages/Appearance/types/privacyTrigger.type';
import { UpdateFormInput } from '../../../types/general';

const placementOptions = [
    {
        label: 'trigger.left',
        icon: PlacementSvg,
        value: 'bottomLeft'
    },
    {
        label: 'trigger.right',
        icon: PlacementSvg,
        value: 'bottomRight'
    }
];

type Props = {
    updateForm: (payload: UpdateFormInput) => void;
    placement: PrivacyTriggerFormState['placement'];
};

export const PrivacyTriggerPlacement = ({ updateForm, placement }: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.privacyTrigger'
    });

    const handleOnChange = (val: string) => {
        updateForm({
            id: 'placement',
            value: val
        });
    };

    return (
        <Box>
            <Stack spacing="14px">
                <Stack spacing="4px">
                    <Text fontSize="21" fontWeight="600">
                        {t('title')}
                    </Text>
                    <Text>{t('desc')}</Text>
                </Stack>
                <RadioCardGroup
                    value={placement}
                    onChange={handleOnChange}
                    spacing={4}
                >
                    <Stack direction="row" spacing={2}>
                        {placementOptions.map((layout, index) => {
                            const { icon: IconCmp, label, value } = layout;

                            return (
                                <Label
                                    textAlign="center"
                                    key={value}
                                    width="220px"
                                >
                                    <RadioCard value={value}>
                                        <RadioCardBody
                                            display="flex"
                                            justifyContent={
                                                index === 0 ? 'start' : 'end'
                                            }
                                            alignItems="end"
                                        >
                                            <IconCmp />
                                        </RadioCardBody>
                                    </RadioCard>
                                    {t(label)}
                                </Label>
                            );
                        })}
                    </Stack>
                </RadioCardGroup>
            </Stack>
        </Box>
    );
};
