import { Box, Checkbox, Input, Text } from '@usercentrics/cookiebot-ui-react';
import debounce from 'debounce';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigurationProps } from '../../../pages/Configuration/types/Configuration.types';
import { SETTINGS_SHOW_CMP_AFTER_DAYS_MIN } from '../../../util/constants';

export const ReshowCmp = (props: ConfigurationProps) => {
    const { configuration, saveConfiguration } = props;

    const { t } = useTranslation('translation', {
        keyPrefix: 'configurationPage.legal'
    });

    const [isInvalid, setIsInvalid] = useState(false);
    const [isReshowCmpLoading, setIsReshowCmpLoading] = useState(false);
    const [isReshowAfterDaysLoading, setIsReshowAfterDaysLoading] =
        useState(false);

    const handleReshowCmp = (reshowCmp: boolean) => {
        setIsReshowCmpLoading(true);

        saveConfiguration({
            ...configuration,
            ccpa: { ...configuration.ccpa, reshowCmp }
        }).finally(() => setIsReshowCmpLoading(false));
    };

    const handleReshowAfterDays = (days: number) => {
        setIsReshowAfterDaysLoading(true);

        saveConfiguration({
            ...configuration,
            ccpa: { ...configuration.ccpa, reshowAfterDays: days }
        }).finally(() => setIsReshowAfterDaysLoading(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedInput = useCallback(
        debounce((days: number) => {
            handleReshowAfterDays(days);
        }, 500),
        [handleReshowAfterDays]
    );

    const onInputChange = (value: string) => {
        const days = parseInt(value, 10);

        const isValid = days >= SETTINGS_SHOW_CMP_AFTER_DAYS_MIN;
        setIsInvalid(!isValid);

        if (!isValid || days === configuration.ccpa?.reshowAfterDays) {
            return;
        }
        debouncedInput(days);
    };

    return (
        <Box display="flex" p={4} gap={2}>
            <Checkbox
                size="lg"
                mt={1}
                isChecked={!!configuration.ccpa?.reshowCmp}
                onChange={(event) => handleReshowCmp(event.target.checked)}
                isDisabled={isReshowCmpLoading || isReshowAfterDaysLoading}
            />
            <Box display="flex" flexDir="column" gap={2}>
                <Text fontSize="18px" fontWeight="600">
                    {t('reshowCmp')}
                </Text>
                <Text>{t('reshowAfterDays')}</Text>
                {configuration.ccpa?.reshowCmp ? (
                    <Box me="auto">
                        <Input
                            type="number"
                            defaultValue={configuration.ccpa.reshowAfterDays}
                            min={SETTINGS_SHOW_CMP_AFTER_DAYS_MIN}
                            isInvalid={isInvalid}
                            placeholder={t('reshowAfterDaysPlaceholder')}
                            w={180}
                            onChange={(event) =>
                                onInputChange(event.target.value)
                            }
                            isDisabled={
                                isReshowCmpLoading || isReshowAfterDaysLoading
                            }
                        />
                        {isInvalid ? (
                            <Text color="red" fontSize="small">
                                {t('reshowAfterDaysError')}
                            </Text>
                        ) : null}
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};
