import { Box, Divider, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { ConfigurationProps } from '../../../pages/Configuration/types/Configuration.types';
import { CcpaRegionSelection } from './CcpaRegionSelection';
import { HideDoNotSellMyInformation } from './HideDoNotSellMyInformation';
import { ReshowCmp } from './ReshowCmp';
import { ShowCmpCheckbox } from './ShowCmpCheckbox';

export const LegalTab = (props: ConfigurationProps) => {
    const { configuration, saveConfiguration } = props;
    const { t } = useTranslation();

    return (
        <Box>
            <Stack spacing="40px">
                <Box
                    backgroundColor="#F2F2F2"
                    borderRadius="16px"
                    px="40px"
                    py="40px"
                >
                    <Stack spacing="16px">
                        <Text fontSize="28px" fontWeight="700">
                            {t('configurationPage.legal.title')}
                        </Text>
                        <Text fontSize="15px" color="#141414">
                            {t('configurationPage.legal.description')}
                        </Text>
                    </Stack>
                </Box>

                <Box backgroundColor="#FFF" borderRadius="16px" px="40px">
                    <Stack spacing="24px">
                        <Box>
                            <Text fontSize="21px" fontWeight="700" mb="16px">
                                {t(
                                    'configurationPage.textLabels.regionalSettingTitle'
                                )}
                            </Text>
                            <CcpaRegionSelection
                                configuration={configuration}
                                saveConfiguration={saveConfiguration}
                            />
                        </Box>
                        <Divider />
                        <ShowCmpCheckbox
                            configuration={configuration}
                            saveConfiguration={saveConfiguration}
                        />
                        <Divider />
                        <ReshowCmp
                            configuration={configuration}
                            saveConfiguration={saveConfiguration}
                        />
                        <Divider />
                        <HideDoNotSellMyInformation
                            configuration={configuration}
                            saveConfiguration={saveConfiguration}
                        />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
};
