import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    getAppearanceConfiguration,
    setAppearanceConfiguration
} from '../../api/api';
import { useAuthStore } from '../../stores';
import { AppearanceConfiguration } from '../Appearance/types/appearanceConfiguration.type';

export const useConfiguration = () => {
    const { appInstanceId } = useAuthStore();
    const toast = useToast();
    const { t } = useTranslation();

    const [configuration, setConfiguration] =
        useState<AppearanceConfiguration>();

    const { isLoading: isGetLoading } = useQuery({
        queryKey: ['getConfigurations', appInstanceId],
        queryFn: () => getAppearanceConfiguration(appInstanceId),
        cacheTime: 0,
        enabled: !!appInstanceId,
        onSuccess: setConfiguration
    });

    const { mutateAsync: updateConfiguration } = useMutation({
        mutationFn: setAppearanceConfiguration
    });

    const saveConfiguration = async (settings: AppearanceConfiguration) => {
        const originalData = { ...configuration! };
        setConfiguration(settings);
        await updateConfiguration(
            {
                appInstanceId,
                payload: settings
            },
            {
                onSuccess: () => {
                    toast({
                        title: t('configurationPage.updateSuccess'),
                        status: 'success',
                        duration: 3000,
                        isClosable: true
                    });
                },
                onError: () => {
                    setConfiguration(originalData);
                    toast({
                        title: t('configurationPage.updateError'),
                        status: 'error',
                        duration: 3000,
                        isClosable: true
                    });
                }
            }
        );
    };

    return {
        configuration,
        isGetLoading,
        saveConfiguration
    };
};
