import { useCallback, useState } from 'react';

import { ConsentBannerFormState } from '../pages/Appearance/types/consentBanner.type';
import { consentBannerInitialFormState } from '../pages/Appearance/utils/appearanceFormStateUtils';
import { UpdateFormInput } from '../types/general';
import { useAmplitude } from './useAmplitude';

export const useConsentBannerForm = () => {
    const [consentBannerFormState, setConsentBannerFormState] =
        useState<ConsentBannerFormState>(consentBannerInitialFormState);
    const { sendEvent } = useAmplitude();

    const updateConsentBannerForm = useCallback(
        ({ formSection, subSection, value, id }: UpdateFormInput) => {
            sendEvent('Appearance - update custom theme', {
                formSection,
                subSection,
                value,
                id
            });
            setConsentBannerFormState((prevState: any) => {
                if (subSection && formSection) {
                    return {
                        ...prevState,
                        [formSection]: {
                            ...prevState[formSection],
                            [subSection]: {
                                ...prevState[formSection][subSection],
                                [id]: value
                            }
                        }
                    };
                }

                if (formSection) {
                    return {
                        ...prevState,
                        [formSection]: {
                            ...prevState[formSection],
                            [id]: value
                        }
                    };
                }

                return { ...prevState, [id]: value };
            });
        },
        [sendEvent]
    );

    return {
        updateConsentBannerForm,
        consentBannerFormState,
        setConsentBannerFormState
    };
};
