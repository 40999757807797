import { BadgeStatus } from '../../api/generated-types';
import { Component, ComponentState } from '../../types/general';
import { Colors } from '../../util/constants';

export type SortState = {
    column: keyof Component | null;
    direction: 'asc' | 'desc' | 'default';
};

export const badgeToStateMap: Record<BadgeStatus, ComponentState> = {
    [BadgeStatus.Blocked]: ComponentState.BLOCKED,
    [BadgeStatus.Red]: ComponentState.ACTION_REQUIRED,
    [BadgeStatus.Grey]: ComponentState.NONE
};

export const stateTranslationMap: Record<ComponentState, string> = {
    [ComponentState.BLOCKED]: 'reportPage.blocked',
    [ComponentState.ACTION_REQUIRED]: 'common.actionRequired',
    [ComponentState.NONE]: ''
};

export const stateColorMap: Record<ComponentState, string> = {
    [ComponentState.BLOCKED]: Colors.Success,
    [ComponentState.ACTION_REQUIRED]: Colors.Warning,
    [ComponentState.NONE]: ''
};
