import {
    Box,
    Button,
    Select,
    SelectButton,
    SelectList,
    SelectOption
} from '@usercentrics/cookiebot-ui-react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineSettings } from 'react-icons/md';

import { useAmplitude } from '../../../hooks';
import { Language } from '../../../pages/Content/types/Content.types';
import { FormUpdate } from '../../../types/general';

type Props = {
    selectedLanguage: string;
    changeTab: (tab: number) => void;
    addedLanguages: Language[];
} & FormUpdate;

export const LanguageSelection = ({
    selectedLanguage,
    updateForm,
    changeTab,
    addedLanguages
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'contentPage.textLabels'
    });
    const { sendEvent } = useAmplitude();

    const handleOnLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
        updateForm({
            id: 'selectedLanguage',
            value: event.target.value
        });
    };

    const handleOnManageLanguagesClick = () => {
        sendEvent('Content - Manage Languages clicked');
        changeTab(1);
    };

    return (
        <Box display="flex" alignItems="center" gap={4}>
            <Select
                width="clamp(200px, 20vw, 300px)"
                value={selectedLanguage}
                size="sm"
                onChange={handleOnLanguageChange}
            >
                <SelectButton width="300px">{t('selectLanguage')}</SelectButton>
                <SelectList>
                    {addedLanguages.map((language) => (
                        <SelectOption
                            value={language.value}
                            key={language.value}
                        >
                            {language.label}
                        </SelectOption>
                    ))}
                </SelectList>
            </Select>

            <Button
                variant="transparent"
                onClick={handleOnManageLanguagesClick}
            >
                <Box display="flex" gap={2} alignItems="center">
                    <MdOutlineSettings size={24} /> {t('manageLanguages')}
                </Box>
            </Button>
        </Box>
    );
};
