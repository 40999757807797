import { SvgProps } from '../../types/general';

export const SettingsSvg = ({
    fill = '#0C27A0',
    iconColor = '#fff',
    width = '71px',
    height = '71px'
}: SvgProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 71 71"
        height={height as string}
        width={width as string}
    >
        <g id="img/settings-gear-rounded" fill="none" fillRule="evenodd">
            <circle
                cx="35.5"
                cy="35.5"
                r="35.5"
                fillOpacity="100%"
                fill={fill as string}
            />
            <g transform="translate(20.4125 20.4125)">
                <path
                    d="M26.0809754 16.5370833c.0591667-.4733333.1035417-.9466666.1035417-1.4495833s-.044375-.97625-.1035417-1.4495833l3.1210417-2.440625c.2810417-.221875.355-.62125.1775-.9466667l-2.9583333-5.11791667c-.1775-.32541666-.576875-.44375-.9022917-.32541666l-3.683125 1.47916666c-.7691667-.59166666-1.5975-1.07979166-2.4997917-1.44958333L18.7738921.91708333c-.044375-.355-.355-.62125-.7247917-.62125h-5.9166666c-.3697917 0-.6804167.26625-.7247917.62125L10.8455588 4.836875c-.9022917.36979167-1.73062503.87270833-2.4997917 1.44958333l-3.683125-1.47916666c-.34020833-.133125-.72479166 0-.90229166.32541666L.8020171 10.250625c-.19229167.3254167-.10354167.7247917.1775.9466667l3.12104167 2.440625c-.05916667.4733333-.10354167.9614583-.10354167 1.4495833 0 .488125.044375.97625.10354167 1.4495833L.9795171 18.9777083c-.28104167.221875-.355.62125-.1775.9466667l2.95833334 5.1179167c.1775.3254166.576875.44375.90229166.3254166l3.683125-1.4791666c.76916667.5916666 1.5975 1.0797916 2.4997917 1.4495833l.5620833 3.9197917c.044375.355.355.62125.7247917.62125h5.9166666c.3697917 0 .6804167-.26625.7247917-.62125l.5620833-3.9197917c.9022917-.3697917 1.730625-.8727083 2.4997917-1.4495833l3.683125 1.4791666c.3402083.133125.7247917 0 .9022917-.3254166l2.9583333-5.1179167c.1775-.3254167.1035417-.7247917-.1775-.9466667l-3.1210417-2.440625zm-10.9902083 3.7275c-2.8547917 0-5.17708333-2.3222916-5.17708333-5.1770833s2.32229163-5.17708333 5.17708333-5.17708333c2.8547917 0 5.1770833 2.32229163 5.1770833 5.17708333 0 2.8547917-2.3222916 5.1770833-5.1770833 5.1770833z"
                    id="settings-gear-round-a"
                    fill={iconColor as string}
                />
            </g>
        </g>
    </svg>
);
