import { useEffect } from 'react';

export const useLocalInstance = () => {
    useEffect(() => {
        if (import.meta.env.VITE_LOCAL_INSTANCE) {
            const instance = import.meta.env.VITE_LOCAL_INSTANCE;

            const url = new URL(window.location.href);

            if (!url.searchParams.has('instance')) {
                window.location.href = `${
                    url.origin
                }?instance=${instance}&${url.searchParams.toString()}`;
            }
        }
    }, []);
};
